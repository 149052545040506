// i18next-extract-mark-ns-start products-engdyn-bearing

import { ENGDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductEngdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							ENGDYN directly couples the crankshaft and
							cylinder-block dynamics with the bearing oil film to
							allow the user to perform a range of analyses to aid
							bearing design. ENGDYN incorporates a hierarchy of
							solution methods, from the industry standard mobility
							method through to more rigorous hydrodynamic (HD) and
							elastohydrodynamic (EHD) models. Whether early in a
							programme, when only concept-level models are available,
							or when detailed studies are required, ENGDYN provides
							the tools for the engineer to assess bearing
							performance.
						</p>
						<h2>Specific bearing applications</h2>
						<ul>
							<li>Bearing-type selection</li>
							<li>Bearing durability</li>
							<li>Friction and mass-flow calculations</li>
							<li>Load transfer to powertrain</li>
						</ul>
						<h2>Key features</h2>
						<ul>
							<li>
								Direct coupling of crankshaft and crankcase dynamics
								with bearing
							</li>
							<li>
								Mass-conserving hydrodynamic-lubrication modelling
							</li>
							<li>Boundary lubrication modelling&nbsp;</li>
							<li>Thermal-balance modelling</li>
							<li>
								Viscosity-pressure and viscosity-temperature
								dependence
							</li>
							<li>
								Compressible flow to include effect of oil aeration
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{ENGDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductEngdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-engdyn-bearing", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/engdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;